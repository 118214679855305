<template>
  <div style="width: 100%; height: 100%">
    <video
      style="width: 100%; height: 100%"
      ref="videoPlayer"
      class="video-js"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import zh from "video.js/dist/lang/zh-CN.json";

videojs.addLanguage("zh-CN", zh);

export default {
  // name: "VideoPlayer",
  props: {
    src: String,
    type: {
      type: String,
      default: "application/x-mpegURL",
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  methods: {
    pause() {
      this.player && this.player.pause();
    },
    replay() {
      this.player && this.player.play();
    },
    requestFullscreen() {
      this.player && this.player.requestFullscreen();
    },
    initPlayer() {
      return new Promise((resolve, reject) => {
        this.player = videojs(
          this.$refs.videoPlayer,
          {
            language: "zh-CN",
            ...this.options,
          },
          resolve
        );
        // resolve();
      }).catch((e) => {
        console.log(e);
        reject();
      });
    },
    play(src) {
      this.player.src({
        src: src,
        type: this.type,
      });
      this.player.play();
    },
  },
  watch: {
    src(val) {
      if (!val) {
        this.pause();
        return;
      }
      if (!this.player) {
        this.initPlayer().then(() => this.play(this.src));
      } else {
        this.play(this.src);
      }
    },
  },
  mounted() {
    if (this.src) this.initPlayer().then(() => this.play(this.src));
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>