<template>
  <div ref="playerBox" class="play-container">
    <div class="video-box">
      <!-- 预览图 -->
      <div v-show="loading && preView" class="preshowing">
        <img :src="preView" />
      </div>
      <hls-player
        v-if="playerIndex == 1"
        ref="player1"
        key="hls1"
        :src="hlsPlayUrl"
      ></hls-player>
      <!-- <div
        :id="id"
        ref="player"
        key="hls2"
        style="width: 100%; height: 100%"
      ></div> -->
      <ezuikit-player
        ref="ezuikitPlayer"
        @play="() => (playerStatus = true)"
        @stop="() => (playerStatus = false)"
        :url="ysyUrl"
        :accessToken="accessToken"
        v-if="playerIndex == 2"
      ></ezuikit-player>
      <!-- <video-plug
        :currentDeviceInfo="hkvideo"
        v-if="playerIndex == 3"
      ></video-plug> -->
    </div>
    <div class="control-bar">
      <span
        style="
          margin-left: 20px;
          display: flex;
          width: 120px;
          justify-content: space-between;
        "
      >
        <svg
          @click="pause"
          v-if="playerStatus"
          t="1567596200214"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1340"
          width="32"
          height="32"
        >
          <path
            d="M884.736 896H139.264c-6.144 0-11.264-5.12-11.264-11.264V139.264c0-6.144 5.12-11.264 11.264-11.264h745.984c6.144 0 11.264 5.12 11.264 11.264v745.984c-0.512 5.632-5.632 10.752-11.776 10.752z"
            p-id="1341"
            fill="red"
          ></path>
        </svg>
        <svg
          v-else
          @click="play"
          t="1567596067868"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1122"
          width="32"
          height="32"
        >
          <path
            d="M320.552713 167.630858l467.602838 269.970688c57.836243 33.391505 57.836243 116.871801 0 150.263306L320.552713 857.836563c-57.836243 33.391505-130.131803-8.348132-130.131802-75.132165V242.763023c-0.001023-66.784032 72.294536-108.523669 130.131802-75.132165z"
            fill="#ffffff"
            p-id="1123"
          ></path>
        </svg>
        <!-- <svg
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(8)"
          class="icon"
          viewBox="0 0 1024 1024"
          width="32"
          height="32"
        >
          <path
            d="M443.744 68.256c207.36 0 375.456 168.128 375.456 375.488 0 100.064-39.168 191.04-103.008 258.336l215.2 215.168a32 32 0 0 1 0 45.248l-3.008 3.04a32 32 0 0 1-45.28 0l-218.24-218.304a373.76 373.76 0 0 1-221.12 71.968c-207.36 0-375.488-168.096-375.488-375.456S236.384 68.256 443.744 68.256z m0 68.288a307.2 307.2 0 1 0 0 614.4 307.2 307.2 0 0 0 0-614.4z m2.112 136.512a32 32 0 0 1 32 32V409.6l104.544 0.032a32 32 0 0 1 32 32v4.256a32 32 0 0 1-32 32h-104.544V582.4a32 32 0 0 1-32 32H441.6a32 32 0 0 1-32-32v-104.544H305.024a32 32 0 0 1-32-32V441.6a32 32 0 0 1 32-32H409.6l0.032-104.544a32 32 0 0 1 32-32h4.256z"
            p-id="6528"
            fill="#ffffff"
          ></path>
        </svg>
        <svg
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(9)"
          class="icon"
          viewBox="0 0 1024 1024"
          width="32"
          height="32"
        >
          <path
            d="M415.488 742.912c-185.6 0-336.64-151.04-336.64-336.64s151.04-336.64 336.64-336.64 336.64 151.04 336.64 336.64-151.04 336.64-336.64 336.64z m0-596.48c-143.232 0-259.84 116.608-259.84 259.84 0 143.232 116.608 259.84 259.84 259.84 143.232 0 259.84-116.608 259.84-259.84 0-143.232-116.48-259.84-259.84-259.84z"
            p-id="6327"
            fill="#ffffff"
          ></path>
          <path
            d="M580.1856 664.96l54.528-54.0672 299.4944 302.0288-54.528 54.08zM232.448 376.832h345.6v51.2h-345.6z"
            p-id="6328"
            fill="#ffffff"
          ></path>
        </svg> -->
      </span>
      <span v-if="isFullscreen" style="position: absolute; right: 10px">
        <svg
          @click="exitFullscreen"
          class="icon"
          viewBox="0 0 1024 1024"
          width="32"
          height="32"
        >
          <path
            d="M400.595 345.365l-0.948-245.022c-0.42-18.881-16.018-30.215-34.956-30.637h-25.406c-18.88-0.42-33.874 16.018-33.457 34.881l1.061 133.251-168.117-165.421c-18.274-18.311-47.844-18.311-66.119 0-18.218 18.314-18.218 47.907 0 66.236l166.575 164.885-127.697 0.512c-18.88-0.477-36.394 12.606-39.26 34.899v24.080c0.477 18.917 16.077 34.558 34.957 34.972l243.826-1.438c0.362 0.035 0.608 0.171 0.928 0.171l17.1 0.378c9.441 0.226 17.9-3.467 23.923-9.593 6.124-6.083 8.382-14.58 8.131-24.078l-1.821-17.138c0.001-0.335 1.27-0.562 1.27-0.945z"
            p-id="7332"
            fill="#ffffff"
          ></path>
          <path
            d="M766.211 701.451l127.524-0.512c18.88 0.421 36.357-11.183 39.26-33.474v-24.077c-0.478-18.922-16.134-34.558-34.957-35.037l-240.702 1.458c-0.378 0-0.605-0.151-0.967-0.151l-17.062-0.42c-9.441-0.226-17.95 3.469-23.98 9.611-6.159 6.030-8.361 14.559-8.173 24.057l1.881 17.1c0.033 0.42-1.234 0.661-1.234 0.986l0.986 241.248c0.477 18.863 16.078 30.162 34.957 30.576l24.017 0.037c18.827 0.433 33.874-16.055 33.403-34.941l-1.062-130.388 168.117 166.502c18.276 18.314 47.809 18.314 66.085 0 18.255-18.31 18.255-47.906 0-66.218l-168.095-166.366z"
            p-id="7333"
            fill="#ffffff"
          ></path>
          <path
            d="M392.992 618.855c-6.028-6.14-14.541-9.834-23.923-9.61l-17.104 0.42c-0.346 0-0.566 0.151-0.948 0.151l-243.81-1.458c-18.881 0.478-34.503 16.112-34.956 35.034v24.078c2.843 22.292 20.357 33.892 39.206 33.474l129.158 0.42-167.983 166.37c-18.234 18.255-18.234 47.906 0 66.218 18.256 18.314 47.845 18.314 66.102 0l168.137-165.418-1.079 131.185c-0.42 18.922 14.579 35.413 33.424 34.938h25.406c18.937-0.477 34.54-11.713 34.956-30.637l0.987-243.050c0-0.346-1.267-0.571-1.267-0.949l1.821-17.104c0.206-9.495-1.993-18.025-8.116-24.053z"
            p-id="7334"
            fill="#ffffff"
          ></path>
          <path
            d="M615.434 387.559c6.030 6.123 14.541 9.819 23.965 9.553l17.060-0.378c0.378 0 0.608-0.132 0.986-0.19l244.19 1.457c18.88-0.434 34.482-16.078 34.956-34.994l0.058-24.078c-2.898-22.331-20.439-35.355-39.26-34.939l-129.573-0.511 166.483-164.893c18.31-18.235 18.31-47.83 0.054-66.143-18.276-18.311-47.809-18.311-66.084 0l-168.117 166.447 1.079-134.276c0.454-18.863-14.598-35.355-33.424-34.939h-24.017c-18.881 0.477-34.484 11.773-34.957 30.637l-0.967 245.075c0 0.378 1.251 0.608 1.251 0.948l-1.859 17.138c-0.192 9.499 2.007 17.991 8.173 24.078z"
            p-id="7335"
            fill="#ffffff"
          ></path>
        </svg>
      </span>
      <span v-else style="position: absolute; right: 10px">
        <svg
          @click="requestFullscreen"
          class="icon"
          viewBox="0 0 1024 1024"
          width="32"
          height="32"
        >
          <path
            d="M218.688721 168.644955l-0.013303 0 0-0.014326L218.688721 168.644955l128.45665-0.556679c19.003819 0.508583 36.624129-13.658068 39.493479-37.807071L386.638851 104.19713c-0.474814-20.503986-16.169261-37.442774-35.158754-37.897122l-245.311055 1.561565c-0.37146-0.040932-0.605797-0.186242-0.935302-0.186242l-17.193591-0.412392c-9.494235-0.24764-18.012236 3.756557-24.087605 10.389628-6.151094 6.591115-8.428973 15.797801-8.18031 26.090215l1.843998 18.562774c0 0.37146-1.280156 0.61296-1.280156 1.025353l0.956791 265.449721c0.412392 20.454868 16.121166 32.736589 35.165918 33.190937l25.552979 0c19.003819 0.454348 34.092469-17.359366 33.665751-37.786605l-1.073448-144.370085 169.152515 179.211615c18.377556 19.84293 48.128137 19.84293 66.518996 0 18.329461-19.835767 18.329461-51.897997 0-71.755254L218.688721 168.644955z"
            p-id="6890"
            fill="#ffffff"
          ></path>
          <path
            d="M948.293761 899.895471c-0.034792-0.460488 1.238201-0.722454 1.238201-1.073448l-0.014326-0.007163-0.977257-261.362636c-0.481977-20.441565-16.182564-32.67519-35.186384-33.128515l-24.163329-0.034792c-18.934235-0.467651-34.085306 17.385972-33.603329 37.849026l1.073448 141.253094L687.535899 603.016947c-18.405185-19.850094-48.106648-19.850094-66.49853 0-18.36323 19.835767-18.36323 51.897997 0 71.734788l169.104419 180.25027-128.297015 0.550539c-18.989493-0.454348-36.576033 12.115945-39.493479 36.273135l0 26.083052c0.481977 20.4958 16.237823 37.442774 35.17922 37.951357l242.160295-1.582031c0.391926 0 0.611937 0.164752 0.970094 0.164752l17.173125 0.454348c9.495258 0.240477 18.054191-3.76372 24.115234-10.417257 6.212492-6.529717 8.407484-15.763009 8.222265-26.062586L948.293761 899.895471z"
            p-id="6891"
            fill="#ffffff"
          ></path>
          <path
            d="M386.225435 603.009783l-0.014326 0c-18.36323-19.84907-48.14144-19.84907-66.505693 0L150.561088 782.214235l1.086751-142.119835c0.413416-20.4958-14.675234-38.364773-33.610492-37.849026l-25.574469 0c-19.051915 0.509606-34.745339 12.68695-35.172057 33.183773l-0.99056 263.323291c0 0.37146 1.286296 0.61296 1.286296 1.025353l-1.843998 18.521842c-0.206708 10.299577 2.00875 19.540032 8.167007 26.062586 6.068206 6.653537 14.634302 10.657734 24.067138 10.416234l17.207917-0.454348c0.358157 0 0.578168-0.164752 0.970094-0.164752l245.290589 1.589195c18.989493-0.522909 34.71157-17.455557 35.172057-37.95852l0-26.076912c-2.862187-24.157189-20.482497-36.72032-39.438221-36.273135l-129.955794-0.454348 169.001065-180.24413C404.568199 654.970202 404.568199 622.845551 386.225435 603.009783z"
            p-id="6892"
            fill="#ffffff"
          ></path>
          <path
            d="M941.984055 77.652568c-6.068206-6.632047-14.634302-10.637268-24.115234-10.354835l-17.152658 0.412392c-0.385786 0-0.61296 0.144286-0.99056 0.206708L654.063553 66.333778c-19.017122 0.474814-34.704406 17.414625-35.186384 37.910425l-0.048095 26.090215c2.917446 24.190959 20.565385 38.302351 39.486316 37.841863l130.368186 0.556679L621.181655 347.381755c-18.418488 19.75288-18.418488 51.815109-0.048095 71.6519l0 0.007163c18.377556 19.84293 48.093345 19.84293 66.491367 0l169.132048-180.327018-1.086751 145.471163c-0.461511 20.434401 14.696724 38.309514 33.617655 37.85619l24.163329 0c18.989493-0.522909 34.69008-12.756535 35.172057-33.197076l0.977257-265.512143c0-0.412392-1.258667-0.661056-1.258667-1.032516l1.851161-18.562774C950.391539 93.449345 948.183244 84.237543 941.984055 77.652568z"
            p-id="6893"
            fill="#ffffff"
          ></path>
        </svg>
      </span>
    </div>
    <!-- <div class="turn-group">
      <div class="row1">
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(4)"
          class="column1 turn-left-top"
        ></span>
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(0)"
          class="column2 turn-top"
        ></span>
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(6)"
          class="column3 turn-right-top"
        ></span>
      </div>
      <div class="row2">
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(2)"
          class="column1 turn-left"
        ></span>
        <span class="column2"></span>
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(3)"
          class="column3 turn-right"
        ></span>
      </div>
      <div class="row3">
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(5)"
          class="column1 turn-left-bottom"
        ></span>
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(1)"
          class="column2 turn-bottom"
        ></span>
        <span
          @mouseup="() => yunControl(false)"
          @mousedown="() => yunControl(7)"
          class="column3 turn-right-bottom"
        ></span>
      </div>
    </div> -->
  </div>
</template>

<script>
import hlsPlayer from "./hlsPlayer";
import EzuikitPlayer from "./ezuikitPlayer";
// import videoPlug from "../videoplug/videoPlug";
import axios from "axios";
import CU from "@/common/util"

const DIRECTION_MAP = [
  "UP",
  "DOWN",
  "LEFT",
  "RIGHT",
  "LEFT_UP",
  "LEFT_DOWN",
  "RIGHT_UP",
  "RIGHT_DOWN",
  "ZOOM_IN",
  "ZOOM_OUT",
];

export default {
  props: {
    videoInfo: Object, //接口返回的video信息
  },
  components: {
    hlsPlayer,
    // videoPlug,
    EzuikitPlayer,
  },
  data() {
    return {
      id: (Math.random() * 100000).toFixed(0).toString(),
      // 确定使用播放器(1 流  2 萤石云 3 海康插件)
      playerIndex: -1,
      hlsPlayUrl: "",
      ysyUrl: "",
      // 海康插件所需数据
      hkvideo: null,
      accessToken: "",
      deviceSerial: "",

      fullscreen: false,
      playerStatus: false,
      isFullscreen: false,

      preView: "",
      loading: true,
    };
  },
  methods: {
    //   处理视频信息
    dealVideo() {
      if (!this.videoInfo) {
        this.playerIndex = -1;
        return;
      }

      // if(!this.videoInfo.httpUrl)return;


      // 视频流
      if (this.videoInfo.deviceType == 1) {
        this.hlsPlayUrl = "";
        this.playerStatus = false;
        this.preView = "";
        this.playerIndex = 1;
        // 获取播放流地址
        this.$post(this.$api.BUSSDEVICEVIDEO.PREVIEW, {
          deviceKey: this.videoInfo.httpUrl,
        }).then((res) => {
          let url = CU.removePath(res.url)
          this.hlsPlayUrl = 'https://castoff.hogdata.cn/' + url;
          this.playerStatus = true;
          this.loading = false;
        });
        return;
      }
      // // 萤石云
      if (this.videoInfo.deviceType == 2) {
        // this.playerIndex = 2;
        this.hlsPlayUrl = "";
        this.playerIndex = 2;

        let query = this.videoInfo.httpUrl.split("?")[1];
        let paramsStrList = query.split("&");
        let params = {};
        for (let i = 0; i < paramsStrList.length; i++) {
          let tempList = paramsStrList[i].split("=");
          params[tempList[0]] = tempList[1];
        }
        params.deviceSerial = params.url.split("/")[3];
        this.accessToken = params.accessToken;
        this.deviceSerial = params.deviceSerial;
        this.ysyUrl = params.url;
        axios({
          method: "post",
          headers: {
            "Content-Type": " application/x-www-form-urlencoded",
          },
          url: `https://open.ys7.com/api/lapp/device/capture?accessToken=${this.accessToken}&deviceSerial=${this.deviceSerial}&channelNo=1`,
        })
          .then((res) => {
            this.preView = res.data.data.picUrl;
          })
          .catch((e) => {
            console.log(e);
            this.preView = "";
          })
          .finally(() => {
            setTimeout(() => {
              try {
                this.loading = false;
              } catch (e) {
                console.log(e);
              }
            }, 5 * 1000);
          });

        params.deviceSerial = params.url.split("/")[3];
        this.accessToken = params.accessToken;
        this.deviceSerial = params.deviceSerial;
        axios({
          method: "post",
          url: `https://open.ys7.com/api/lapp/v2/live/address/get?accessToken=${params.accessToken}&deviceSerial=${params.deviceSerial}&protocol=2&quality=2`,
          data: {
            accessToken: params.accessToken,
            deviceSerial: params.deviceSerial,
            protocol: 2,
            quality: 2,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            console.log(response);
            // let url = response.data.data.url;
            this.hlsPlayUrl = response.data.data.url;
            this.playerStatus = true;
          })
          .catch((e) => {
            console.log(e);
            this.hlsPlayUrl = "";
            this.playerStatus = false;
          });
        return;
      }

      // // 海康视频播放插件
      // this.playerIndex = 3;
      // this.hkvideo = {
      //   videoIp: this.videoInfo.linkIp,
      //   videoPort: this.videoInfo.devicePort,
      //   videoUserName: this.videoInfo.loginUser,
      //   videoPwd: this.videoInfo.loginPwd,
      //   iChannelID: +this.videoInfo.channelNo,
      // };
      // this.loading = false;
    },

    yunControl(direction) {
      if (this.playerIndex == 2) this.controlYSY(direction);
      else if (this.playerIndex == 1) this.controlHLS(direction);
    },

    controlYSY(direction) {
      if (direction == false && direction !== 0) return;
      axios({
        method: "post",
        url: `https://open.ys7.com/api/lapp/device/ptz/start?accessToken=${this.accessToken}&deviceSerial=${this.deviceSerial}&channelNo=${this.videoInfo.channelNo}&direction=${direction}&speed=2`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then(() => {
          axios({
            method: "post",
            url: `https://open.ys7.com/api/lapp/device/ptz/stop?accessToken=${this.accessToken}&deviceSerial=${this.deviceSerial}&channelNo=${this.videoInfo.channelNo}&direction=${direction}`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
            .then((resp) => {
              console.log(resp);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    controlHLS(direction) {
      if (direction == false && direction !== 0) return;
      let command = DIRECTION_MAP[direction];
      this.$post(this.$api.RECORD.VIDEO_CONTROLLING, {
        id: this.videoInfo.id,
        action: "0",
        command: command,
      }).then(() => {
        this.$post(this.$api.RECORD.VIDEO_CONTROLLING, {
          id: this.videoInfo.id,
          action: "1",
          command: command,
        });
      });
    },
    // handlerFullscreen() {
    //   this.fullscreen = !this.fullscreen
    //   console.log(this.fullscreen)
    // },
    pause() {
      switch (this.playerIndex) {
        // 萤石云
        case 2:
          this.$refs.ezuikitPlayer && this.$refs.ezuikitPlayer.pause();
          break;
        //推流
        case 1:
          this.$refs.player1.pause();
          break;
      }
      this.loading = true;
      // this.playerStatus = false;
    },
    // pause1() {
    //   this.$refs.player1.pause();
    //   this.playerStatus = false;
    // },
    play() {
      switch (this.playerIndex) {
        // 萤石云
        case 2:
          this.$refs.ezuikitPlayer && this.$refs.ezuikitPlayer.play();
          break;
        //推流
        case 1:
          this.$refs.player1.replay();
          break;
      }
      setTimeout(() => {
        try {
          this.loading = false;
        } catch (e) {
          console.log(e);
        }
      }, 3 * 1000);
      // this.playerStatus = true;
    },
    requestFullscreen() {
      let videoDom = this.$refs.playerBox;
      if (videoDom.requestFullscreen) {
        videoDom.requestFullscreen();
      } else if (videoDom.webkitRequestFullScreen) {
        videoDom.webkitRequestFullScreen();
      } else if (videoDom.mozRequestFullScreen) {
        videoDom.mozRequestFullScreen();
      } else {
        videoDom.msRequestFullscreen();
      }
    },
    // requestFullscreen1() {
    //   let videoDom = this.$refs.playerBox1;
    //   if (videoDom.requestFullscreen) {
    //     return videoDom.requestFullscreen();
    //   } else if (videoDom.webkitRequestFullScreen) {
    //     return videoDom.webkitRequestFullScreen();
    //   } else if (videoDom.mozRequestFullScreen) {
    //     return videoDom.mozRequestFullScreen();
    //   } else {
    //     return videoDom.msRequestFullscreen();
    //   }
    // },
    exitFullscreen() {
      document.exitFullscreen();
    },
    dealFullscreenChange() {
      if (document.fullscreenElement) {
        // 萤石云全屏
        if (this.playerIndex == 2)
          this.$nextTick(() => {
            this.$refs.ezuikitPlayer.requestFullscreen();
          });
        this.isFullscreen = true;
      } else {
        if (this.playerIndex == 2)
          this.$nextTick(() => {
            this.$refs.ezuikitPlayer.exitFullscreen();
          });
        this.isFullscreen = false;
      }
    },
  },
  watch: {
    videoInfo() {
      this.dealVideo();
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.dealFullscreenChange);
    this.dealVideo();
    // window.addEventListener("fullscreenchange", this.handlerFullscreen);
    // window.addEventListener("mozfullscreenchange", this.handlerFullscreen);
    // window.addEventListener("webkitfullscreenchange", this.handlerFullscreen);
    // window.addEventListener("msfullscreenchange", this.handlerFullscreen);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.dealFullscreenChange);
  },
};
</script>

<style lang="less" scoped>
.play-container {
  width: 100%;
  height: 100%;
  position: relative;
  .operate {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;
  }
  .video-box {
    width: 100%;
    height: 100%;
    position: relative;
    .preshowing {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .control-bar {
    position: absolute;
    background: rgba(0, 0, 0, 0.65);
    padding: 10px 0;
    height: 52px;
    box-sizing: border-box;
    color: #fff;
    left: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 4;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .turn-group {
    position: absolute;
    width: 100%;
    height: calc(~"100% - 52px");
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;

    .row1 {
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      width: 100%;
      display: flex;
    }

    .row2 {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      display: flex;
    }

    .row3 {
      flex-grow: 0;
      flex-shrink: 0;
      height: 50px;
      width: 100%;
      display: flex;
    }

    .column1 {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50px;
    }

    .column2 {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
    }

    .column3 {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50px;
    }

    .turn-left-top {
      cursor: url(./imgs/zuoshangjiao.png), auto;
    }

    .turn-top {
      cursor: url(./imgs/een1.png), auto;
    }

    .turn-right-top {
      cursor: url(./imgs/CornerTopR.png), auto;
    }

    .turn-left {
      cursor: url(./imgs/jiantou-copy-copy.png), auto;
    }

    .turn-right {
      cursor: url(./imgs/iconfontyoujiantou.png), auto;
    }

    .turn-left-bottom {
      cursor: url(./imgs/CornerBotL.png), auto;
    }

    .turn-bottom {
      cursor: url(./imgs/een.png), auto;
    }

    .turn-right-bottom {
      cursor: url(./imgs/youxiajiao.png), auto;
    }
  }
}
</style>