<template>
  <div style="width: 100%; height: 100%" :id="id" ref="player"></div>
</template>
<script>
// import EZUIKit from "ezuikit-js";

export default {
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    accessToken: String,
    url: String,
    template: { type: String, default: "simple" },
  },
  data() {
    return {
      id: (Math.random() * 100000).toFixed(0).toString(),
      player: null,
      originWidth: 0,
      originHeight: 0,
    };
  },
  methods: {
    requestFullscreen() {
        let width=window.screen.availWidth;
        let height=window.screen.availHeight;
      this.player && this.player.reSize(width,height);
    },
    exitFullscreen() {
      this.player && this.player.reSize(this.originWidth, this.originHeight);
    },
    initPlayer() {
      let tempParams = {
        autoplay: this.autoplay,
        id: this.id,
        accessToken: this.accessToken,
        url: this.url,
        width: this.originWidth,
        height: this.originHeight,
        template: this.template,
      };
      this.player = new EZUIKit.EZUIKitPlayer(tempParams);
    },
    play(url) {
      if (!this.player) {
        this.$emit("stop");
        return;
      }
      this.player
        .play(url)
        .then(() => {
          this.$emit("play");
        })
        .catch(() => {
          this.$emit("stop");
        });
    },
    pause() {
      if (!this.player) {
        this.$emit("stop");
        return;
      }
      this.player.stop().then(() => {
        this.$emit("stop");
      });
    },
  },
  watch: {
    url(url) {
      this.initPlayer();
      this.play(url);
    },
  },
  mounted() {
    this.originWidth = this.$refs.player.scrollWidth;
    this.originHeight = this.$refs.player.scrollHeight;
    this.initPlayer();
    this.play();
  },
};
</script>